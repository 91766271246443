import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { ElementListItem, HeaderProps, MenuListImage, MenuListItem, NamedListItem, MenuSubListItem } from './types';
import { clsx } from 'clsx';
import gradleLogo from './logo-gradle.svg';
import './header.css';
import CtaSection from './CtaSection';

const horizontalLineClasses = 'h-1 w-9 my-1 rounded-full bg-white transition ease-linear transform duration-300';
const HamburgerIcon = () => {
  return (
    <div className="ham flex flex-col items-center justify-center lg:hidden">
      <div className={horizontalLineClasses} />
      <div className={horizontalLineClasses} />
      <div className={horizontalLineClasses} />
    </div>
  );
};

const ArrowSymbol = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    className="float-right my-4 size-8 peer-checked/submenu:rotate-180 lg:hidden"
  >
    <path
      fillRule="evenodd"
      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
      clipRule="evenodd"
    />
  </svg>
);

const Header: React.FC<HeaderProps> = props => {
  return (
    <header className="w-full bg-black">
      <menu className="text-body max-w-section relative flex flex-row flex-wrap content-center items-center px-3 text-white lg:flex-nowrap lg:justify-center">
        <Link href="/" className="float-left flex h-16 pb-5 pt-3">
          <Image src={gradleLogo} alt="Logo" width={120} height={34} priority={true} />
        </Link>
        <label className="flex flex-grow justify-end">
          <input type="checkbox" className="peer/menu invisible" id="hamburger-menu" autoComplete="off" tabIndex={0} />
          <HamburgerIcon />
          <div className="absolute left-0 top-16 z-[999] hidden max-h-[calc(100svh_-_var(--navbarHeight)_-_var(--navbarBannerMobileHeight))] w-full overflow-y-scroll border-b-[1px] border-t-[1px] border-gray-3 bg-black pb-2 peer-checked/menu:block md:max-h-[calc(100svh_-_var(--navbarHeight)_-_var(--navbarBannerHeight))] lg:static lg:z-auto lg:block lg:w-auto lg:overflow-visible lg:border-none lg:pb-0">
            <ul className="relative flex flex-col items-center text-[1rem] lg:flex-row">
              {props.menuList.map((item, index: number) => (
                <li
                  key={`menuList-${index}`}
                  className="group/menu w-full leading-[4rem] lg:w-auto [&:last-child]:pl-4 [&:not(:last-child)]:px-4"
                >
                  {(item as ElementListItem).element || renderMainMenuNamedItem(item)}
                </li>
              ))}
            </ul>
          </div>
        </label>
      </menu>
    </header>
  );
};
export default Header;

function renderMainMenuNamedItem(item: MenuListItem) {
  const namedItem = item as NamedListItem;
  const submenuLength = item?.submenu?.length || 0;
  const submenuClasses = clsx(
    'hidden lg:absolute text-[0.875rem] leading-5',
    'lg:group-hover/menu:block peer-checked/submenu:block',
    submenuLength > 1 && 'lg:-left-[40%]', // move left for big menu
  );

  return (
    <label className="inline-block w-full">
      {!!namedItem.href && submenuLength > 0 && (
        <>
          <span className="whitespace-nowrap lg:hidden">{namedItem.name}</span>
          <Link href={namedItem.href} className="mega-menu-link hidden w-full whitespace-nowrap lg:inline-block">
            {namedItem.name}
          </Link>
        </>
      )}
      {!!namedItem.href && submenuLength === 0 && (
        <Link href={namedItem.href} className="mega-menu-link w-full whitespace-nowrap">
          {namedItem.name}
        </Link>
      )}
      {!namedItem.href && <span className="whitespace-nowrap">{namedItem.name}</span>}
      {submenuLength > 0 && (
        <>
          <input type="checkbox" className="peer/submenu" hidden autoComplete="off" />
          <ArrowSymbol />
          <ul className={submenuClasses}>{renderSubmenu(item.submenu!, namedItem.ctaSection)}</ul>
        </>
      )}
    </label>
  );
}

function renderSubmenuItem(item: MenuSubListItem, index: number) {
  if (item.submenu) {
    return (
      <li key={`subMenuItem-${index}`}>
        <Link
          key={`link-${index}`}
          href={item.href || '#'}
          className="mega-menu-link inline-block w-full hover:text-gradle-blue"
        >
          {item.name}
        </Link>
        <ul key={`list-${index}`} className="ml-4">
          {item.submenu.map((item, index: number) => renderSubmenuItem(item as MenuSubListItem, index))}
        </ul>
      </li>
    );
  } else if (item.href) {
    return (
      <li key={`subMenuItem-${index}`}>
        <Link href={item.href} className="mega-menu-link inline-block w-full hover:text-gradle-blue">
          {item.name}
        </Link>
      </li>
    );
  } else if (item.caption) {
    //skip top margin for first caption in small menu
    const captionClasses = clsx(
      'text-body-bold text-[0.875rem] leading-5 text-black',
      index === 0 ? 'mb-2' : 'mb-2 mt-4',
    );
    return (
      <li key={`caption-${index}`} className={captionClasses}>
        {item.name}
      </li>
    );
  }
}

function renderSubmenu(submenuList: MenuSubListItem[][], ctaSection?: MenuListImage) {
  const submenuContainerClasses = clsx(
    'left-0 top-14 rounded-lg bg-white text-gray-2 overflow-y-scroll pb-4',
    ctaSection && ' lg:pb-0', // no bottom padding if menu has image on lg+
    submenuList.length > 1 && 'lg:w-[1200px] px-4 lg:pr-0 flex lg:flex-row flex-col whitespace-break-spaces', // large submenu
    submenuList.length == 1 && 'p-4 lg:w-48 whitespace-normal', //small submenu
  );
  return (
    <div className={submenuContainerClasses}>
      {submenuList.map((items: MenuSubListItem[], index: number) => (
        <ul
          key={index}
          className={clsx(submenuList.length > 1 && 'flex w-full flex-col gap-1 py-4 last:pb-4 lg:w-1/4')}
        >
          {items.map((item: MenuSubListItem, itemIndex: number) => renderSubmenuItem(item, itemIndex))}
        </ul>
      ))}
      {ctaSection && <CtaSection />}
    </div>
  );
}
