import Image from 'next/image';
import Link from 'next/link';
import { HeaderMenuList } from '@/components/Header/types';
import Button from '@/components/Button';

export const menuList: HeaderMenuList = [
  {
    name: 'Solutions',
    ctaSection: {
      bgImg: '/developer-productivity-engineering/',
      href: 'https://dpeuniversity.gradle.com/app/',
    },
    submenu: [
      [
        { name: 'Key capabilities', caption: true },
        { name: 'Overview', href: '/gradle-enterprise-solutions/' },
        {
          name: 'Performance Acceleration & Continuity Solution Overview',
          href: '/gradle-enterprise-solutions/performance-acceleration-overview/',
          submenu: [
            {
              name: 'Build Cache',
              href: '/gradle-enterprise-solutions/build-cache/',
            },
            {
              name: 'Predictive Test Selection',
              href: '/gradle-enterprise-solutions/predictive-test-selection/',
            },
            {
              name: 'Test Distribution',
              href: '/gradle-enterprise-solutions/test-distribution/',
            },
            {
              name: 'Performance Continuity',
              href: '/gradle-enterprise-solutions/performance-continuity/',
            },
          ],
        },
        {
          name: 'Build Scan®',
          href: '/gradle-enterprise-solutions/build-scan-root-cause-analysis-data/',
        },
        {
          name: 'Failure Analytics',
          href: '/gradle-enterprise-solutions/failure-analytics/',
        },
        {
          name: 'Management Reporting & Insights',
          href: '/gradle-enterprise-solutions/management-reporting-and-insights/',
        },
        {
          name: 'CI Build Cache & Resource Profiling',
          href: '/gradle-enterprise-solutions/ci-build-cache-resource-profiling/',
        },
        { name: 'Platform Services', caption: true },
        {
          name: 'Performance & Scalability',
          href: '/gradle-enterprise-solutions/gradle-enterprise-mission-critical-performance-and-scalability/',
        },
        {
          name: 'Security',
          href: '/develocity/solutions/security/',
        },
        {
          name: 'Integrations',
          href: '/gradle-enterprise-integrations/',
        },
      ],
      [
        { name: 'Solutions for…', caption: true },
        {
          name: 'DPE Engineers & Managers',
          href: '/dpe-engineers-managers/',
        },
        { name: 'Senior Engineers', href: '/senior-engineers/' },
        { name: 'Build Engineers', href: '/build-engineers/' },
        {
          name: 'VPs & Directors of Engineering',
          href: '/vps-and-directors-of-engineering/',
        },
        {
          name: 'CI & DevOps Engineers',
          href: '/ci-engineers-and-devops-engineers/',
        },
        {
          name: 'Application Engineers',
          href: '/application-engineers/',
        },
        { name: 'Build Systems', caption: true },
        {
          name: 'Android',
          href: '/gradle-enterprise-solutions/android-build-system/',
        },
        {
          name: 'Bazel',
          href: '/gradle-enterprise-solutions/bazel-build-system/',
        },
        {
          name: 'Gradle Build Tool',
          href: '/gradle-enterprise-solutions/gradle-build-tool/',
        },
        {
          name: 'Maven',
          href: '/gradle-enterprise-solutions/maven-build-tool/',
        },
        {
          name: 'Scala & sbt',
          href: '/gradle-enterprise-solutions/sbt-build-system/',
        },
      ],
    ],
  },
  {
    name: 'Pricing',
    href: '/pricing/',
  },
  {
    name: 'Customers',
    submenu: [
      [
        { name: 'Commercial', caption: true },
        {
          name: 'Success Stories',
          href: '/customers',
        },
        { name: 'OSS Projects', caption: true },
        {
          name: 'Profiles & Results',
          href: '/customers/oss-projects/',
        },
      ],
    ],
  },
  {
    name: 'Learning Center',
    href: '/learning-center-by-objective/',
    submenu: [
      [
        {
          name: 'All Resources',
          href: '/learning-center-by-objective/',
        },
        {
          name: '- By Learning Objective',
          href: '/learning-center-by-objective/',
        },
        {
          name: '- By Journey Phase',
          href: '/learning-center-by-journey-phase/',
        },
        {
          name: '- By Key Topics',
          href: '/learning-center-by-key-topics/',
        },
      ],
      [
        { name: 'DPE University', href: 'https://dpeuniversity.gradle.com/app/' },
        { name: 'Tour Develocity', href: '/develocity/product-tour/home/' },
        { name: 'Events & Training', href: '/training/' },
        { name: 'ROI Calculator', href: '/roi-calculator/' },
        { name: 'Documentation', href: '/enterprise/resources/' },
        {
          name: 'YouTube Channel',
          href: 'https://www.youtube.com/channel/UCvClhveoEjokKIuBAsSjEwQ',
        },
        { name: 'Blog', href: '/blog/' },
      ],
    ],
    ctaSection: {
      bgImg: '/developer-productivity-engineering/',
      href: 'https://dpeuniversity.gradle.com/app/',
    },
  },
  {
    name: 'Company',
    submenu: [
      [
        { name: 'Who We Are', href: '/who-we-are/' },
        { name: 'Our Story', href: '/our-story/' },
        { name: 'Working at Gradle', href: '/careers/' },
        { name: 'Our Team', href: '/our-team/' },
        { name: 'Press & Media Center', href: '/press-media/' },
        { name: 'Brand', href: '/brand/' },
      ],
    ],
  },
  {
    element: (
      <Link
        href="https://gradle.org/"
        className="mega-menu-link flex flex-row flex-nowrap items-center gap-1 whitespace-nowrap"
      >
        <Image
          src="/assets/images/build-tool.svg"
          alt="Logo"
          width={36}
          height={32}
          className="-translate-y-[5px] p-0"
        />
        <span className="text-white">Build Tool</span>
      </Link>
    ),
  },
  {
    element: (
      <Button href="/develocity/trial/" variant="primary" className="mega-menu-link">
        Request a Trial
      </Button>
    ),
  },
];
