'use client';

import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { menuList } from '@/components/Header/header.copy';
import Banner from '@/components/Banner';
import { useBannerState } from '@/lib/contexts/BannerContext';

export default function MainWebsiteLayout({ children }: { children: React.ReactNode }) {
  const { isBannerOpen, handleCloseBanner } = useBannerState();

  return (
    <>
      <div className="sticky top-0 z-[101]">
        <Banner handleCloseBanner={handleCloseBanner} isBannerOpen={isBannerOpen} />
        <Header menuList={menuList} />
      </div>

      <main className="bg-white" id="main">
        {children}
      </main>
      <Footer />
    </>
  );
}
