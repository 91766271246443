import Link from 'next/link';
import Logo from '../Logo';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import dpeUniLogo from './edu-logo.png';

const CtaSection = () => {
  return (
    <div className="text-body relative w-full rounded-2xl p-8 text-white lg:block lg:min-h-[340px] lg:w-1/2 lg:rounded-none">
      <div className="relative z-20 flex h-full flex-col justify-center gap-5 lg:w-2/3">
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <Logo alt="DpeUniversity Logo" className="w-8" src={dpeUniLogo} />
            <h5 className="text-h5">DPE University</h5>
          </div>
          <p>Start your journey to Developer Productivity Engineering mastery</p>
        </div>
        <Link
          href="https://dpeuniversity.gradle.com/app/"
          className="mega-menu-link text-body underline-text-transition-blue group flex w-fit items-center justify-center gap-1 text-white"
        >
          Get Started
          <ChevronRightIcon className="-mb-0.5 size-6 group-hover:translate-x-1" />
        </Link>
      </div>
      <video
        autoPlay
        muted
        loop
        playsInline
        className="absolute inset-0 h-full w-full rounded-2xl object-cover lg:rounded-none"
      >
        <source src="/assets/videos/demoPage_heroBG.webm" type="video/webm" />
        <source src="/assets/videos/demoPage_heroBG.mp4" type="video/mp4" />
      </video>
      <div className="absolute inset-0 z-10 h-full w-full rounded-2xl bg-gradient-to-r from-[#010002] from-10% lg:rounded-none" />
    </div>
  );
};

export default CtaSection;
